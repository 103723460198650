import React, { useMemo } from "react"

import { useLocation } from "@gatsbyjs/reach-router"
import Head from "decentraland-gatsby/dist/components/Head/Head"
import NotFound from "decentraland-gatsby/dist/components/Layout/NotFound"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import BlogNavigation from "../../../components/Layout/BlogNavigation"
import Layout from "../../../components/Layout/Layout"
import Post from "../../../components/Layout/Post"
import { getBlogpostPreview } from "../../../modules/contentful"
import { transformEntryToBlogPostAttributes } from "../../../modules/formats"

export default function PostPage(props: any) {
  const location = useLocation()
  const options = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return {
      id: params.get("contentful_id") ?? undefined,
      env: params.get("contentful_env") ?? undefined,
      token: params.get("token") ?? undefined,
    }
  }, [location.search])

  const [post, postState] = useAsyncMemo(async () => {
    const post = await getBlogpostPreview(options)
    if (!post) {
      return null
    }
    return transformEntryToBlogPostAttributes(post)
  }, [options])

  const loading = !postState.loaded || postState.loading

  if (postState.loaded && !postState.loading && !post) {
    return (
      <Layout
        {...props}
        isFullscreen
        isOverlay
        hideFooter={false}
        className={"layout__blog"}
      >
        <Container style={{ paddingTop: "75px" }}>
          <NotFound />
        </Container>
      </Layout>
    )
  }

  return (
    <Layout
      {...props}
      isFullscreen
      isOverlay
      hideFooter={false}
      className={TokenList.join(["layout__blog", loading && "loading"])}
    >
      <Head title={post?.title || ""} description={post?.description || ""} />
      <BlogNavigation active={props.category} />
      <Post post={post} loading={loading} />
    </Layout>
  )
}
