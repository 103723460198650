import { createClient } from "contentful"
import env from "decentraland-gatsby/dist/utils/env"
import { memo } from "radash"

import type { IBlogPostFields } from "../@types/contentful"

const getClient = memo(
  (accessToken: string, environment: string, host?: string) => {
    return createClient({
      space: env("CONTENTFUL_SPACE_ID")!,
      accessToken,
      environment,
      host,
    })
  },
  {
    key: (token?: string, environment?: string, host?: string) =>
      [token, environment, host ?? "cdn.contentful.com"].join("::"),
    ttl: Infinity,
  }
)

export type FetchOptions = {
  offset: number
  limit: number
  categoryId?: string
}

export type GetBlogpostPreviewOptions = Partial<{
  id: string
  env: string
  token: string
}>

export const getBlogpostPreview = memo(
  async (options: GetBlogpostPreviewOptions) => {
    if (!options.token || !options.id || !options.env) {
      return null
    }

    const blogEntries = await getClient(
      options.token,
      options.env,
      "preview.contentful.com"
    ).getEntries<IBlogPostFields>({
      content_type: "blog_post",
      "fields.id": options.id,
    })

    if (blogEntries.total === 0) {
      return null
    }

    return blogEntries.items[0]
  }
)
